import React from 'react';
import Anchor from '../anchor/Anchor';
import boaLogo from '../../../assets/images/bofa_logo.svg';

import './ImageAnchor.sass';

export default function ImageAnchor({ imgProps, ...props }) {
	return (
		<Anchor target="_blank" {...props}>
			<img {...imgProps} alt="" />
		</Anchor>
	);
}

ImageAnchor.defaultProps = {
	imgProps: {
		src: boaLogo,
		alt: ''
	},
	'aria-label': 'click to visit the Bank of America website.',
	href: 'https://bankofamerica.com'
};
