import React from 'react';
import './CloseIcon.sass';

export default function CloseIcon() {
	return (
		<svg
			className="close-x"
			width="18px"
			height="18px"
			viewBox="0 0 18 18"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g>
					<polyline points="18 0.707 17.293 0 9 8.293 0.707 0 0 0.707 8.293 9 0 17.293 0.707 18 9 9.707 17.293 18 18 17.293 9.707 9 18 0.707" />
				</g>
			</g>
		</svg>
	);
}
