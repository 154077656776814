import API_CONST from './api';


//url to get location data
export function getLocationDataURL(KeyValue) {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.STATE + KeyValue;
}
//url for resetting password
export function resetPasswordURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.RESETPASSWORD;
}
//url to get states list in step 0 
export function clearActiveProposalURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.CLEARACTIVEPROPOSALS;
}
//url to get the list of all proposals
export function getAllProposalsURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.GETALLPROPOSALS;
}
//url to get the verify url
export function getVerifyURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.VERIFY;
}
//url to get the target location
export function getTargetLocationURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.TARGETLOCATION;
}
//url to delete any particular proposals 
export function deleteProposalURL(proposalId) {
  return (
    process.env.REACT_APP_API_DOMAIN + API_CONST.DELETEPROPSAL + proposalId
  );
}
// url to save steps at step 1, step 2 and step 3
export function saveStepURL(url) {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.SAVESTEP + url;
}
// url to upload any particular media at step3
export function mediaUploadURL(index) {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.MEDIAUPLOAD + index;
}
//url to delete any particular media at step3
export function mediaDeleteURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.MEDIADELETE;
}
//url to get salutataion data
export function getSalutationURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.GETSALUTATION;
}
//url to get relationship with bank data
export function relationshipWithBankURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.RELATIONSHIPWITHBANK;
}
//url to get opportunity frequency data
export function getOpportunityfrequencyURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.OPPRTUNITYFREQUENCY;
}
//url to get sponsor category data
export function getSponsorcategoryURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.SPONSORCATEGORY;
}
//url to get low moderate income benefits data 
export function getLowmoderateincomebenefitsURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.LOWMODERATEINCOMEBENEFITS;
}
//url to get opportunity state data 
export function getOpportunitystateURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.OPPORTUNITYSTATE;
}
//url to get organization state data
export function organizationstatesURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.ORGANIZATIONSTATES;
}
//url for setting active proposals
export function setActiveProposalURL(proposalId) {
  return (
    process.env.REACT_APP_API_DOMAIN + API_CONST.SETACTIVEPROPOSALS + proposalId
  );
}
//url to submit proposal at step 4
export function submitProposalURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.SUBMITPROPOSAL;
}

//url to send otp at step 4
export function sendOTPURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.SENDOTP;
}

//url to validate otp at step 4
export function validateOTPURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.VALIDATEOTP;
}


//url to get opportunity categories
export function getOpportunitycategoriesURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.OPPORTUNITYCATEGORIES;
}
//url to log out
export function logOutURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.LOGOUT;
}
//url to login
export function logInURL() {
  return process.env.REACT_APP_TOKENURL + API_CONST.LOGIN;
}
//url to signup a user
export function signUpURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.SIGNUP;
}
//url to set password in case password is forgotten by user
export function forgotPasswordURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.FORGOTPASSWORD;
}
//url to change password
export function changePasswordURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.CHANGEPASSWORD;
}
//url to get user profile
export function getUserProfileURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.USERPROFILE;
}
//url for emulate user log in
export function emulateUserLogInURL() {
  return process.env.REACT_APP_TOKENURL + API_CONST.EMULATEUSERLOGIN;
}
//url for emulate user authorizatiom 
export function emulateUserAuthorizationURL() {
  return process.env.REACT_APP_TOKENURL + API_CONST.EMULATEUSERAUTHORIZATION;
}
//url to get request type
export function getRequestTypeURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.REQUESTTYPE;
}
//url to send technical request
export function sendTechRequestURL() {
  return process.env.REACT_APP_API_DOMAIN + API_CONST.SENDTECHREQUEST;
}
