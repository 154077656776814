import React from 'react';
import './Arrows.sass';

export const ArrowRight = () => {
	return (
		<svg
			className="arrow-right-icon"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 407.436 407.436"
			style={{ enableBackground: 'new 0 0 407.436 407.436' }}
			xmlSpace="preserve"
		>
			<polygon points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 " />
		</svg>
	);
};

export const ArrowLeft = () => {
	return (
		<svg
			className="arrow-left-icon"
			width="10px"
			height="18px"
			viewBox="0 0 10 18"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g stroke="none" strokeWidth="1" fillRule="evenodd">
				<g transform="translate(-35.000000, -18.000000)">
					<g transform="translate(35.000000, 18.000000)">
						<polyline
							transform="translate(5.000000, 9.000000) scale(-1, 1) translate(-5.000000, -9.000000) "
							points="0.707 0 0 0.707 8.586 8.99 0 17.293 0.707 18 10 8.99 0.707 0"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export const ArrowDown = () => {
	return (
		<svg
			width="18px"
			height="10px"
			viewBox="0 0 18 10"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-365.000000, -22.000000)" fill="#FFFFFF">
					<g>
						<g transform="translate(365.000000, 22.000000)">
							<polyline
								transform="translate(9.000000, 5.000000) rotate(-360.000000) translate(-9.000000, -5.000000) "
								points="17.293 0 9.011 8.585 0.707 0 0 0.707 9.011 10 18 0.707 17.293 0"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
