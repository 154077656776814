import React from "react";
import "./Footer.sass";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";
import PillIcon from "./PillIcon";
import Button from "../../atoms/button/Button";

function isHeaderVisible(location) {
  if (
    location.pathname.toLowerCase().includes("/noaccess") ||
    location.pathname.toLowerCase().includes("/restricted")
  ) {
    return false;
  }

  return true;
}

function Footer({ isAuthenticated, isVisible }) {
  const location = useLocation();
  isVisible = isHeaderVisible(location);

  if (isVisible) {
    return (
      <footer className="global-footer">
        <Container fluid>
          <p>
            For assistance with submitting a request, there is a{" "}
            {location.pathname.toLowerCase().includes("/uploads") ? (
              <a
                href={
                  process.env.PUBLIC_URL +
                  "/upload/Financial_Document_Upload_How_to_guide.pdf"
                }
                id={`request`}
                rel={`noreferrer nofollow`}
                target={`_blank`}
              >
                Request How-to Document
              </a>
            ) : (
              <a
                href={
                  process.env.PUBLIC_URL + "/sponsorship/sponsorship_how_to.pdf"
                }
                id={`sponsorship-request`}
                rel={`noreferrer nofollow`}
                target={`_blank`}
              >
                Sponsorship Request How-to Document
              </a>
            )}
            .
          </p>
          <p>
            For answers to your technical support questions, please use the{" "}
            {isAuthenticated ? (
              <NavLink to={`/home/help`} id={`sponsorship-help`}>
                {location.pathname.toLowerCase().includes("/uploads")
                  ? "Technical Help System"
                  : "Sponsorship Technical Help System"}
              </NavLink>
            ) : (
              <NavLink to={`/help`} id={`sponsorship-help`}>
                {location.pathname.toLowerCase().includes("/uploads")
                  ? "Technical Help System"
                  : "Sponsorship Technical Help System"}
              </NavLink>
            )}
            .
          </p>
          {/* <p>
            <a
              href={`https://www.bankofamerica.com/privacy/overview.go`}
              id={`privacy-security`}
              target={`_blank`}
              rel={`noreferrer nofollow`}
            >
              Privacy &amp; Security
            </a>
          </p> */}
          <div className="privacy-choices-container">
            <Button
              link
              id={`privacy-security`}
              className="link ot-sdk-show-settings"
            />
            &nbsp;
            <PillIcon knockout />
          </div>
        </Container>
      </footer>
    );
  } else {
    return null;
  }
}

const mapStateToProps = ({ authentication: { isAuthenticated } }) => ({
  isAuthenticated,
});

export default connect(mapStateToProps)(Footer);
