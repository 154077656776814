import React, { Component } from 'react';
import {compose } from 'redux'
import { connect } from "react-redux";

import Modal, { ModalHeader } from './../organisms/modals/baseModal/Modal';
import Time from '../../assets/images/icon_time-bofa.svg';
import {sessionLogOut} from '../../ducks/authentication';

function HOC(ComposedClass) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        signoutTime: process.env.REACT_APP_IDEAL_TIMEOUT,
        isSessionExpired: false
      };
      this.handleLogin = this.handleLogin.bind(this);
    }

    componentDidMount() {
      if (isLogin()) {
        this.events = [
          'load',
          'mousemove',
          'mousedown',
          'click',
          'scroll',
          'keypress'
        ];
        for (var i in this.events) {
          window.addEventListener(this.events[i], this.resetTimeout);
        }

        this.setTimeout();
      }
    }

    clearTimeoutFunc = () => {
      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
      this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
      this.clearTimeoutFunc();
      this.setTimeout();
    };

    logout = () => {
      this.props.sessionLogOut();      
      this.setState({ isSessionExpired: true });
    };

    handleLogin = () => {
      this.setState({ isSessionExpired: false });
      window.location = '/';
    };

    render() {
      return (
        <>
          <ComposedClass {...this.props} />
          <Modal
            trigger={false}
            show={this.state.isSessionExpired}
            showCloseButton={false}
            backdrop={`static`}
            footerButtonProps={[
              {
                blue: true,
                children: <>Log in</>,
                onClick: () => this.handleLogin()
              }
            ]}
          >
            <ModalHeader icon={Time}>
              <div className={`mt-3`}>Session timeout</div>
            </ModalHeader>
            Due to inactivity, your session has been timed out. Please log back
            in to continue.
          </Modal>
        </>
      );
    }
  };
}

export const isLogin = () => {
  if (sessionStorage.getItem('access_token')) {
    return true;
  }

  return false;
};

const mapDispatchToProps = { sessionLogOut };

export default compose(connect(null, mapDispatchToProps), HOC);
