import React from "react";
const Step0 = React.lazy(() => import("./Step0"));
const Step1 = React.lazy(() => import("./Step1"));
const Step2 = React.lazy(() => import("./Step2"));
const Step3 = React.lazy(() => import("./Step3"));
const Step4 = React.lazy(() => import("./Step4"));
const Dashboard = React.lazy(() => import("./Dashboard"));
const Login = React.lazy(() => import("./Login"));
const Verify = React.lazy(() => import("./Verify"));
const Upload = React.lazy(() => import("./Upload"));

const Styleguide = React.lazy(() => import("./Styleguide"));
const Faq = React.lazy(() => import("./Faq"));
const SignUp = React.lazy(() => import("./SignUp"));
const TechHelp = React.lazy(() => import("./TechHelp"));
const ChangePassword = React.lazy(() => import("./ChangePassword"));
const ResetPassword = React.lazy(() => import("./ResetPassword"));
const Home = React.lazy(() => import("./Home"));
const NotFound = React.lazy(() => import("./NotFound"));
const EmulateUser = React.lazy(() => import("./EmulateUser"));
const NoAccess = React.lazy(() => import("./NoAccess"));
const Restricted = React.lazy(() => import("./RestrictedPage"));
const DefaultPage = React.lazy(() => import("./default"));
const MaintenancePage = React.lazy(() => import("./Maintenance"));
export {
  Step0,
  Step1,
  Step2,
  Step3,
  Step4,
  Dashboard,
  Login,
  Styleguide,
  Faq,
  SignUp,
  TechHelp,
  ChangePassword,
  ResetPassword,
  Home,
  NotFound,
  EmulateUser,
  NoAccess,
  Restricted,
  DefaultPage,
  Verify,
  Upload,
  MaintenancePage
};
