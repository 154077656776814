import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Collapse } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ImageAnchor from "../../atoms/imageAnchor/ImageAnchor";
import boaFlag from "../../../assets/images/bofa_lo3_rgb_symbol.svg";
import hamburger from "../../../assets/images/m_hamburger menu.svg";
import Button from "../../atoms/button/Button";
import CloseIcon from "../../../assets/CloseIcon";
import { logOut } from "../../../ducks/authentication";

import "./Header.sass";

const NavItem = ({ children, tag, ...props }) => {
  const Tag = tag || NavLink;
  return (
    <div className="global-nav-item">
      <Tag {...props}>{children}</Tag>
    </div>
  );
};

const AuthenticatedLinks = ({ liClassName, idSuffix, actions, ...props }) => {
  const routes = [
    {
      url: "/home/dashboard",
      title: "Dashboard"
    },
    {
      url: "/logout",
      title: "Log Out",
      tag: Button,
      transparent: true,
      onClick: () => {
        actions.logOut();
      }
    }
  ];
  return routes.map((u, i) => {
    const { url, title, ...rest } = u;
    const lastClassName = i === routes.length - 1 ? "last-p-route" : "";
    return (
      <li key={url} className={`${liClassName || ""} ${lastClassName}`}>
        <NavItem
          id={`${title.toLowerCase().replace(/ /g, "-")}-route-${idSuffix}`}
          to={url}
          {...rest}
        >
          {u.title}
        </NavItem>
      </li>
    );
  });
};

AuthenticatedLinks.propTypes = {
  /** Class name that will be applied to all protected routes */
  liClassName: PropTypes.string,
  /** unique suffix applied to each navigation item */
  idSuffix: PropTypes.string.isRequired
};

function isHeaderVisible() {
  if (
    window.location.href.toLowerCase().includes("/noaccess") ||
    window.location.href.toLowerCase().includes("/restricted") ||
    window.APP.SITECONFIG.IsInMaintenance
  ) {
    return false;
  }

  return true;
}

function Header({ isAuthenticated, logOut, isVisible, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  isVisible = isHeaderVisible();
  return (
    <header className="global-header">
      {isAuthenticated && (
        <nav
          className="protected-navigation d-none d-md-block"
          aria-label="Desktop protected routes"
        >
          <ul>
            <AuthenticatedLinks actions={{ logOut }} idSuffix="desktop" />
          </ul>
        </nav>
      )}
      <div className="main-navigation-container">
        <div className="boa-logo-link">
          <ImageAnchor id="header-boa-logo-link" data-link-num={10000} />
        </div>
        {isVisible && (
          <>
            <Button
              id="main-nav-collapse-btn"
              aria-label={`Click to ${
                isOpen ? "close" : "open"
              } main navigation`}
              className="main-nav-container-collapse-btn d-block d-md-none"
              transparent
              onClick={toggle}
              aria-controls="main-nav-collapse"
              aria-expanded={isOpen}
            >
              {isOpen ? <CloseIcon /> : <img src={hamburger} alt="" />}
            </Button>
            <nav
              className="main-nav-container d-none d-md-block"
              aria-label="Desktop navigation"
            >
              <ul>
                <li>
                  <div className={`global-nav-item`}>
                    <a
                      href={`https://about.bankofamerica.com/en-us/index.html`}
                      id={`about-us-top-nav`}
                      target={`_blank`}
                      rel={`nofollow noreferrer`}
                    >
                      <img src={boaFlag} alt="" /> About Us
                    </a>
                  </div>
                </li>
                <li>
                  <div className={`global-nav-item`}>
                    <a
                      href={`https://about.bankofamerica.com/en-us/what-guides-us/find-grants-sponsorships.html`}
                      id={`sponsorship-overview-top-nav`}
                      target={`_blank`}
                      rel={`nofollow noreferrer`}
                    >
                      Sponsorship Overview
                    </a>
                  </div>
                </li>
                <li>
                  {isAuthenticated ? (
                    <NavItem id="faq-top-nav" to="/home/faq">
                      FAQ
                    </NavItem>
                  ) : (
                    <NavItem id="faq-top-nav" to="/faq">
                      FAQ
                    </NavItem>
                  )}
                </li>
              </ul>
            </nav>
          </>
        )}
      </div>
      {isVisible && (
        <Collapse
          isOpen={isOpen}
          id="main-nav-collapse"
          className={`d-md-none`}
        >
          <nav className="main-nav-container" aria-label="Mobile navigation">
            <ul>
              {isAuthenticated && (
                <AuthenticatedLinks
                  liClassName="protected-routes d-block d-md-none"
                  idSuffix="mobile-dropdown"
                  actions={{ logOut }}
                />
              )}
              <li>
                <div className={`global-nav-item`}>
                  <a
                    href={`https://about.bankofamerica.com/en-us/index.html`}
                    id={`about-us-mobile-dropdown`}
                    target={`_blank`}
                    rel={`nofollow noreferrer`}
                  >
                    <img src={boaFlag} alt="" /> About Us
                  </a>
                </div>
              </li>
              <li>
                <div className={`global-nav-item`}>
                  <a
                    href={`https://about.bankofamerica.com/en-us/what-guides-us/find-grants-sponsorships.html`}
                    target={`_blank`}
                    id={`sponsorship-overview-mobile-dropdown`}
                    rel={`nofollow noreferrer`}
                  >
                    Sponsorship Overview
                  </a>
                </div>
              </li>
              <li>
                {isAuthenticated ? (
                  <NavItem id="faq-top-nav" to="/home/faq">
                    FAQ
                  </NavItem>
                ) : (
                  <NavItem id="faq-top-nav" to="/faq">
                    FAQ
                  </NavItem>
                )}
              </li>
            </ul>
          </nav>
        </Collapse>
      )}
    </header>
  );
}

const mapStateToProps = ({ authentication: { isAuthenticated } }) => ({
  isAuthenticated
});

export default connect(mapStateToProps, { logOut })(Header);

Header.defaultProps = {
  isAuthenticated: false
};
