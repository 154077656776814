import React from 'react';
import PropTypes from 'prop-types';

import './Anchor.sass';

const Anchor = React.forwardRef(function _Anchor(
	{ children, className, target, btn, blueOutline, transparent, tag, disabled, proposalId, ...props },
	ref
) {
	const onClickProxy = (e) => {
		disabled && e.preventDefault();
		typeof props.onClick === 'function' && props.onClick(e);
	};
	target = target ? { target, rel: 'noopener noreferrer' } : null;
	const Tag = tag || 'a';
	const classNames = btn ? `button-link ${className || ''}` : className || '';
	const disabledClass = disabled ? 'isDisabled' : '';
	let btnStyles = blueOutline ? 'blue-outline' : '';
	btnStyles += transparent ? 'transparent-btn' : '';
	return (
		<Tag
			ref={ref}
			className={`anchor ${classNames} ${btnStyles} ${disabledClass}`}
			{...props}
			{...target}
			onClick={onClickProxy}
		>
			{children}
		</Tag>
	);
});

Anchor.propTypes = {
	id: PropTypes.string.isRequired,
	'data-link-num': PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
	href: PropTypes.string.isRequired,
	/** styles anchor to look like a button */
	btn: PropTypes.bool,
	/** btn-styles */
	blueOutline: PropTypes.bool,
	transparent: PropTypes.bool
};

Anchor.defaultProps = {
	target: false,
	id: '0',
	'data-link-num': 0
};

export default Anchor;
