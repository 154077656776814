import React from 'react';
import PropTypes from 'prop-types';
import { Card as BsCard, CardBody as BsCardBody } from 'reactstrap';
import './Card.sass';

export default function Card({ white, noPaddingDesktop, className, ...props }) {
	let colorClass = white ? 'white' : '';
	let paddingClass = noPaddingDesktop ? 'no-d-padding' : '';
	return (
		<BsCard className={`${colorClass} ${paddingClass}`} {...props}>
			<BsCardBody className={`${className || ''}`}>{props.children}</BsCardBody>
		</BsCard>
	);
}

Card.propTypes = {
	// applies a class to turn the card whhite
	white: PropTypes.bool
};
