//import { config } from 'rxjs'
import ERRORS_CONST from "../../constants/errors";
import http from "../../constants/token-interceptor";
import "whatwg-fetch";
import {
  getLocationDataURL,
  clearActiveProposalURL,
  getAllProposalsURL,
  deleteProposalURL,
  mediaUploadURL,
  saveStepURL,
  mediaDeleteURL,
  getSalutationURL,
  relationshipWithBankURL,
  getOpportunityfrequencyURL,
  getSponsorcategoryURL,
  getLowmoderateincomebenefitsURL,
  getOpportunitystateURL,
  organizationstatesURL,
  setActiveProposalURL,
  submitProposalURL,
  getOpportunitycategoriesURL,
  sendOTPURL,
  validateOTPURL,
  getVerifyURL,
} from "../../constants/microservices-helper";
export const RESET_FETCH_STATE = "sponsorship/proposals/RESET_FETCH";
export const SAVE_PROPOSAL_START = "sponsorship/proposals/SAVE_PROPOSAL_START";
export const SAVE_PROPOSAL_SUCCESS =
  "sponsorship/proposals/SAVE_PROPOSAL_SUCCESS";
export const SAVE_PROPOSAL_FAILURE =
  "sponsorship/proposals/SAVE_PROPOSAL_FAILURE";
export const DELETE_PROPOSAL_START =
  "sponsorship/proposals/DELETE_PROPOSAL_START";
export const DELETE_PROPOSAL_SUCCESS =
  "sponsorship/proposals/DELETE_PROPOSAL_SUCCESS";
export const DELETE_PROPOSAL_FAILURE =
  "sponsorship/proposals/DELETE_PROPOSAL_FAILURE";
export const FETCH_ALL_PROPOSALS_START =
  "sponsorship/proposals/FETCH_ALL_PROPOSALS_START";
export const FETCH_ALL_PROPOSALS_SUCCESS =
  "sponsorship/proposals/FETCH_ALL_PROPOSALS_SUCCESS";
export const FETCH_ALL_PROPOSALS_FAILURE =
  "sponsorship/proposals/FETCH_ALL_PROPOSALS_FAILURE";

export const FETCH_VERIFY_START = "sponsorship/proposals/FETCH_VERIFY_START";
export const FETCH_VERIFY_SUCCESS =
  "sponsorship/proposals/FETCH_VERIFY_SUCCESS";
export const FETCH_VERIFY_FAILURE =
  "sponsorship/proposals/FETCH_VERIFY_FAILURE";
export const FETCH_VERIFY_RESET = "sponsorship/proposals/FETCH_VERIFY_RESET";

export const SET_ACTIVE_PROPOSAL = "sponsorship/proposals/SET_ACTIVE_PROPOSAL";
export const CLEAR_ACTIVE_PROPOSAL_SUCCESS =
  "sponsorship/proposals/CLEAR_ACTIVE_PROPOSAL_SUCCESS";
export const CLEAR_ACTIVE_PROPOSAL_START =
  "sponsorship/proposals/CLEAR_ACTIVE_PROPOSAL_START";
export const CLEAR_ACTIVE_PROPOSAL_FAILURE =
  "sponsorship/proposals/CLEAR_ACTIVE_PROPOSAL_FAILURE";

export const GET_LOCATION_DATA_SUCCESS =
  "sponsorship/proposals/GET_LOCATION_DATA_SUCCESS";
export const GET_LOCATION_DATA_START =
  "sponsorship/proposals/GET_LOCATION_DATA_START";
export const GET_LOCATION_DATA_FAILURE =
  "sponsorship/proposals/GET_LOCATION_DATA_FAILURE";
export const SALUTATION_START = "sponsorship/proposals/SALUTATION_START";
export const SALUTATION_SUCCESS = "sponsorship/proposals/SALUTATION_SUCCESS";
export const SALUTATION_FAILURE = "sponsorship/proposals/SALUTATION_FAILURE";
export const RELATION_WITH_BANK_START =
  "sponsorship/proposals/RELATION_WITH_BANK_START";
export const RELATION_WITH_BANK_SUCCESS =
  "sponsorship/proposals/RELATION_WITH_BANK_SUCCESS";
export const RELATION_WITH_BANK_FAILURE =
  "sponsorship/proposals/RELATION_WITH_BANK_FAILURE";
export const ORGANIZATION_STATE_START =
  "sponsorship/proposals/ORGANIZATION_STATE_START";
export const ORGANIZATION_STATE_SUCCESS =
  "sponsorship/proposals/ORGANIZATION_STATE_SUCCESS";
export const ORGANIZATION_STATE_FAILURE =
  "sponsorship/proposals/ORGANIZATION_STATE_FAILURE";
export const FETCH_ALL_OPORTUNITYFREQUENCY_START =
  "sponsorship/proposals/ FETCH_ALL_OPORTUNITYFREQUENCY_START";
export const FETCH_ALL_OPORTUNITYFREQUENCY_SUCCESS =
  "sponsorship/proposals/FETCH_ALL_OPORTUNITYFREQUENCY_SUCCESS";
export const FETCH_ALL_OPORTUNITYFREQUENCY_FAILURE =
  "sponsorship/proposals/FETCH_ALL_OPORTUNITYFREQUENCY_FAILURE";
export const FETCH_ALL_SPONSORCATEGORY_START =
  "sponsorship/proposals/ FETCH_ALL_SPONSORCATEGORY_START";
export const FETCH_ALL_SPONSORCATEGORY_SUCCESS =
  "sponsorship/proposals/FETCH_ALL_SPONSORCATEGORY_SUCCESS";
export const FETCH_ALL_SPONSORCATEGORY_FAILURE =
  "sponsorship/proposals/FETCH_ALL_SPONSORCATEGORY_FAILURE";
export const FETCH_ALL_LOWMODERATEINCOMEBENEFITS_START =
  "sponsorship/proposals/ FETCH_ALL_LOWMODERATEINCOMEBENEFITS_START";
export const FETCH_ALL_LOWMODERATEINCOMEBENEFITS_SUCCESS =
  "sponsorship/proposals/FETCH_ALL_LOWMODERATEINCOMEBENEFITS_SUCCESS";
export const FETCH_ALL_LOWMODERATEINCOMEBENEFITS_FAILURE =
  "sponsorship/proposals/FETCH_ALL_LOWMODERATEINCOMEBENEFITS_FAILURE";
export const FETCH_ALL_OPPORTUNITYSTATES_START =
  "sponsorship/proposals/ FETCH_ALL_OPPORTUNITYSTATES_START";
export const FETCH_ALL_OPPORTUNITYSTATES_SUCCESS =
  "sponsorship/proposals/FETCH_ALL_OPPORTUNITYSTATES_SUCCESS";
export const FETCH_ALL_OPPORTUNITYSTATES_FAILURE =
  "sponsorship/proposals/FETCH_ALL_OPPORTUNITYSTATES_FAILURE";
export const SET_ACTIVE_PROPOSAL_START =
  "sponsorship/proposals/SET_ACTIVE_PROPOSAL_START";
export const SET_ACTIVE_PROPOSAL_FAILURE =
  "sponsorship/proposals/SET_ACTIVE_PROPOSAL_FAILURE";
export const MEDIA_UPLOAD_START = "sponsorship/proposals/MEDIA_UPLOAD_START";
export const MEDIA_UPLOAD_SUCCESS =
  "sponsorship/proposals/MEDIA_UPLOAD_SUCCESS";
export const MEDIA_UPLOAD_FAILURE =
  "sponsorship/proposals/MEDIA_UPLOAD_FAILURE";
export const MEDIA_DELETE_START = "sponsorship/proposals/MEDIA_DELETE_START";
export const MEDIA_DELETE_SUCCESS =
  "sponsorship/proposals/MEDIA_DELETE_SUCCESS";
export const MEDIA_DELETE_FAILURE =
  "sponsorship/proposals/MEDIA_DELETE_FAILURE";
export const SUBMIT_PROPOSAL_START =
  "sponsorship/proposals/SUBMIT_PROPOSAL_START";
export const SUBMIT_PROPOSAL_SUCCESS =
  "sponsorship/proposals/SUBMIT_PROPOSAL_SUCCESS";
export const SUBMIT_PROPOSAL_FAILURE =
  "sponsorship/proposals/SUBMIT_PROPOSAL_FAILURE";
export const OPPORTUNITY_CATEGORY_START =
  "sponsorship/proposals/OPPORTUNITY_CATEGORY_START";
export const OPPORTUNITY_CATEGORY_SUCCESS =
  "sponsorship/proposals/OPPORTUNITY_CATEGORY_SUCCESS";
export const OPPORTUNITY_CATEGORY_FAILURE =
  "sponsorship/proposals/OPPORTUNITY_CATEGORY_FAILURE";

export const SEND_OTP_START = "sponsorship/proposals/SEND_OTP_START";
export const SEND_OTP_SUCCESS = "sponsorship/proposals/SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "sponsorship/proposals/SEND_OTP_FAILURE";

export const VALIDATE_OTP_START = "sponsorship/proposals/VALIDATE_OTP_START";
export const VALIDATE_OTP_SUCCESS =
  "sponsorship/proposals/VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILURE =
  "sponsorship/proposals/VALIDATE_OTP_FAILURE";

export const CLEAR_VALIDATE_OTP_SUCCESS =
  "sponsorship/proposals/CLEAR_VALIDATE_OTP_SUCCESS";
export const CLEAR_SEND_OTP_SUCCESS =
  "sponsorship/proposals/CLEAR_SEND_OTP_SUCCESS";

//   export const GET_MEDIA_START = "sponsorship/proposals/GET_MEDIA_START";
// export const GET_MEDIA_SUCCESS =
//   "sponsorship/proposals/GET_MEDIA_SUCCESS";
// export const GET_MEDIA_FAILURE =
//   "sponsorship/proposals/GET_MEDIA_FAILURE";

// initial states
const initialFetch = {
  isFetching: false,
  fetchSuccess: false,
  error: "",
};
const initialState = {
  ...initialFetch,
  activeProposal: {
    progress: "step0",
  },
};

export default function reducer(state = initialState, { type, payload }) {
  // console.warn(`ACTION: ${type}`)
  switch (type) {
    case RESET_FETCH_STATE:
      return { ...state, ...initialFetch };
    case SAVE_PROPOSAL_START:
    case SAVE_PROPOSAL_FAILURE:
      return { ...state, ...payload };
    case SAVE_PROPOSAL_SUCCESS:
      const { activeProposal, ...restOfPayload } = payload;
      const { activeProposal: currentActiveProposal, ...restOfState } = state;
      return {
        ...restOfState,
        ...restOfPayload,
        activeProposal: { ...currentActiveProposal, ...activeProposal },
      };
    case FETCH_ALL_PROPOSALS_START:
    case FETCH_ALL_PROPOSALS_SUCCESS:
    case FETCH_ALL_PROPOSALS_FAILURE:
      return { ...state, ...payload };
    case FETCH_VERIFY_START:
    case FETCH_VERIFY_SUCCESS:
    case FETCH_VERIFY_FAILURE:
    case FETCH_VERIFY_RESET:
      return { ...state, ...payload };

    case SET_ACTIVE_PROPOSAL_START:
    case SET_ACTIVE_PROPOSAL_FAILURE:
      return { ...state, ...payload };
    case SET_ACTIVE_PROPOSAL:
      const { proposalData } = payload;
      return {
        ...state,
        activeProposal: proposalData,
        ...initialFetch,
      };

    case GET_LOCATION_DATA_START:
    case GET_LOCATION_DATA_FAILURE:
      return { ...state, ...payload };
    case CLEAR_ACTIVE_PROPOSAL_START:
    case CLEAR_ACTIVE_PROPOSAL_SUCCESS:
    case CLEAR_ACTIVE_PROPOSAL_FAILURE:
      return { ...state, ...initialState, ...payload };
    case GET_LOCATION_DATA_SUCCESS:
      const { locationData } = payload;
      return { ...state, locationData };
    case DELETE_PROPOSAL_START:
    case DELETE_PROPOSAL_FAILURE:
      return { ...state, ...payload };
    case DELETE_PROPOSAL_SUCCESS:
      return { ...state };
    case SALUTATION_START:
    case SALUTATION_FAILURE:
      return { ...state, ...payload };
    case SALUTATION_SUCCESS:
      const salutationData = payload.salutation;
      return { ...state, salutationData };
    case RELATION_WITH_BANK_START:
    case RELATION_WITH_BANK_FAILURE:
      return { ...state, ...payload };
    case RELATION_WITH_BANK_SUCCESS:
      const { relationshipData } = payload;
      return { ...state, relationshipData };
    case ORGANIZATION_STATE_START:
    case ORGANIZATION_STATE_FAILURE:
      return { ...state, ...payload };
    case ORGANIZATION_STATE_SUCCESS:
      const { organizationData } = payload;
      return { ...state, organizationData };
    case FETCH_ALL_OPORTUNITYFREQUENCY_START:
    case FETCH_ALL_OPORTUNITYFREQUENCY_FAILURE:
      return { ...state, ...payload };
    case FETCH_ALL_OPORTUNITYFREQUENCY_SUCCESS:
      const { opportunityFrequency } = payload;
      return { ...state, opportunityFrequency };
    case FETCH_ALL_SPONSORCATEGORY_START:
    case FETCH_ALL_SPONSORCATEGORY_FAILURE:
      return { ...state, ...payload };
    case FETCH_ALL_SPONSORCATEGORY_SUCCESS:
      const { sponsorCategory } = payload;
      return { ...state, sponsorCategory };
    case FETCH_ALL_LOWMODERATEINCOMEBENEFITS_START:
    case FETCH_ALL_LOWMODERATEINCOMEBENEFITS_FAILURE:
      return { ...state, ...payload };
    case FETCH_ALL_LOWMODERATEINCOMEBENEFITS_SUCCESS:
      const { lowModerateIncomeBenefits } = payload;
      return { ...state, lowModerateIncomeBenefits };
    case FETCH_ALL_OPPORTUNITYSTATES_START:
    case FETCH_ALL_OPPORTUNITYSTATES_FAILURE:
      return { ...state, ...payload };
    case FETCH_ALL_OPPORTUNITYSTATES_SUCCESS:
      const { opportunityStates } = payload;
      return { ...state, opportunityStates };
    case MEDIA_UPLOAD_SUCCESS:
      const { mediaUplaodResponse } = payload;
      return { ...state, mediaUplaodResponse };
    case MEDIA_UPLOAD_START:
      return { ...state, ...payload };
    case MEDIA_UPLOAD_FAILURE:
      const { mediaUploadError } = payload;
      return { ...state, mediaUploadError };
    case MEDIA_DELETE_SUCCESS:
    case MEDIA_DELETE_START:
    case MEDIA_DELETE_FAILURE:
      return { ...state, ...payload };
    case SUBMIT_PROPOSAL_START:
    case SUBMIT_PROPOSAL_SUCCESS:
    case SUBMIT_PROPOSAL_FAILURE:
      return { ...state, ...payload };
    case SEND_OTP_START:
    case SEND_OTP_SUCCESS:
    case SEND_OTP_FAILURE:
      const { sendOtpSucess } = payload;
      return { ...state, ...payload, sendOtpSucess: sendOtpSucess };
    case CLEAR_SEND_OTP_SUCCESS:
      return { ...state, ...payload, sendOtpSucess: sendOtpSucess };
    case VALIDATE_OTP_START:
    case VALIDATE_OTP_SUCCESS:
    case VALIDATE_OTP_FAILURE:
      const validateOtpResponse = payload;
      return { ...state, ...payload, validateOtpResponse: validateOtpResponse };
    case CLEAR_VALIDATE_OTP_SUCCESS:
      return { ...state, ...payload, validateOtpResponse: validateOtpResponse };
    case OPPORTUNITY_CATEGORY_START:
    case OPPORTUNITY_CATEGORY_FAILURE:
      return { ...state, ...payload };
    case OPPORTUNITY_CATEGORY_SUCCESS:
      const { opportunitycategories } = payload;
      return { ...state, opportunitycategories };
    //   case GET_MEDIA_START:
    // case GET_MEDIA_FAILURE:
    //   return { ...state, ...payload };
    // case GET_MEDIA_SUCCESS:
    //   const { getFileData } = payload;
    //   console.log(">>>>>>>>>>>>>>>>getFileData"+JSON.stringify(getFileData));
    //   return { ...state, getFileData };
    default:
      return state;
  }
}

export const getLocationData = (KeyValue) => {
  return (dispatch) => {
    dispatch({ type: GET_LOCATION_DATA_START, isFetching: true });
    const url = getLocationDataURL(KeyValue);
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_LOCATION_DATA_SUCCESS,
          payload: {
            id: KeyValue,
            locationData: response.data,
            isPicklistFail: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LOCATION_DATA_FAILURE,
          payload: {
            error: ERRORS_CONST.ERROR_FETCHING_PROPOSALS,
            ...error,
            isFetching: false,
            isPicklistFail: true,
          },
        });
      });
  };
};

export const clearActiveProposal = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ACTIVE_PROPOSAL_START, isFetching: true });
    const url = clearActiveProposalURL();
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: CLEAR_ACTIVE_PROPOSAL_SUCCESS,
          payload: {
            remoteStaticData: response.data,
            isFetching: false,
            isPicklistFail: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: CLEAR_ACTIVE_PROPOSAL_FAILURE,
          payload: {
            error: ERRORS_CONST.ERROR_FETCHING_PROPOSALS,
            ...error,
            isFetching: false,
            isPicklistFail: true,
          },
        });
      });
  };
};

export const resetFetchStatus = () => ({
  type: RESET_FETCH_STATE,
  payload: {},
});

export const resetVerify = () => (dispatch) => {
  dispatch({
    type: FETCH_VERIFY_RESET,
    payload: {
      verifyData: null,
      isFetching: false,
      // fetchSuccess: true,
      isLoading: false,
    },
  });
};

export const verify = (query) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_VERIFY_START,
      isFetching: true,
      isLoading: false,
      isProposalSubmitted: false,
      isProposalFailed: false,
    });
    const url = getVerifyURL();
    http
      .post(url, {
        payload: query,
      })
      .then((response) => {
        dispatch({
          type: FETCH_VERIFY_SUCCESS,
          payload: {
            verifyData: response.data,
            isFetching: false,
            // fetchSuccess: true,
            isLoading: true,
          },
        });
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: FETCH_VERIFY_FAILURE,
            payload: {
              error: ERRORS_CONST.ERROR_VERIFY_PROPOSALS,
              ...error,
              verifyData: "REQUEST_EXPIRED",
              // verifyData: error.response.data,
              isFetching: false,
              isLoading: true,
            },
          });
        } else {
          dispatch({
            type: FETCH_VERIFY_FAILURE,
            payload: {
              error: ERRORS_CONST.ERROR_VERIFY_PROPOSALS,
              ...error,
              verifyData: "REQUEST_EXPIRED",
              isFetching: false,
              isLoading: true,
            },
          });
        }
      });
  };
};

export const getAllProposals = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ALL_PROPOSALS_START,
      isFetching: true,
      isLoading: false,
      isProposalSubmitted: false,
      isProposalFailed: false,
    });
    const url = getAllProposalsURL();
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_PROPOSALS_SUCCESS,
          payload: {
            allProposals: response.data,
            isFetching: false,
            // fetchSuccess: true,
            isLoading: true,
          },
        });
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          dispatch({
            type: FETCH_ALL_PROPOSALS_FAILURE,
            payload: {
              error: ERRORS_CONST.ERROR_FETCHING_PROPOSALS,
              ...error,
              allProposals: error.response.data,
              isFetching: false,
              isLoading: true,
            },
          });
        } else {
          dispatch({
            type: FETCH_ALL_PROPOSALS_FAILURE,
            payload: {
              error: ERRORS_CONST.ERROR_FETCHING_PROPOSALS,
              ...error,
              allProposals: "Could not get any proposals",
              isFetching: false,
              isLoading: true,
            },
          });
        }
      });
  };
};

export const deleteProposal = (proposalId) => {
  return (dispatch) => {
    dispatch({ type: DELETE_PROPOSAL_START, isFetching: true });
    const url = deleteProposalURL(proposalId);
    http
      .delete(url)
      .then((response) => {
        dispatch({
          type: DELETE_PROPOSAL_SUCCESS,
          payload: {
            id: proposalId,
            isFetching: false,
            // fetchSuccess: true,
            deleteData: response,
          },
        });
        dispatch(getAllProposals());
      })
      .catch((error) => {
        dispatch({
          type: DELETE_PROPOSAL_FAILURE,
          payload: {
            error: "Could not delete proposal",
            proposalId,
            isFetching: false,
          },
        });
      });
  };
};

export const saveStep = (proposal, url) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_PROPOSAL_START,
      isFetching: true,
      fetchSuccess: false,
    });
    const urlSavestep = saveStepURL(url);
    http
      .post(urlSavestep, proposal)
      .then((response) => {
        dispatch({
          type: SAVE_PROPOSAL_SUCCESS,
          payload: {
            activeProposal: response.data,
            isFetching: false,
            fetchSuccess: true,
          },
        });
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.Status === 5
        ) {
          dispatch({
            type: SAVE_PROPOSAL_FAILURE,
            payload: {
              error: error.response.data.Message,
              ...error.response,
              isFetching: false,
              fetchSuccess: false,
            },
          });
        } else {
          dispatch({
            type: SAVE_PROPOSAL_FAILURE,
            payload: {
              error: "Could not save proposal",
              ...error.response,
              isFetching: false,
              fetchSuccess: false,
            },
          });
        }
      });
  };
};

export const mediaUpload = (formData, index) => {
  return (dispatch) => {
    dispatch({ type: MEDIA_UPLOAD_START, isFetching: true });
    const url = mediaUploadURL(index);
    http
      .post(url, formData)
      .then((response) => {
        dispatch({
          type: MEDIA_UPLOAD_SUCCESS,
          payload: {
            mediaUplaodResponse: response.data,
            isFetching: false,
            // fetchSuccess: true
          },
        });
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: MEDIA_UPLOAD_FAILURE,
            payload: {
              isFetching: false,
              mediaUploadError: error.config.url,
            },
          });
        }
      });
  };
};

export const mediaDelete = (mediaBody) => {
  return (dispatch) => {
    dispatch({ type: MEDIA_DELETE_START, isFetching: true });
    const url = mediaDeleteURL();
    http.delete(url, { data: mediaBody }).then((response) => {
      dispatch({
        type: MEDIA_DELETE_SUCCESS,
        payload: {
          mediaDelete: response.data,
          isFetching: false,
          //  fetchSuccess: true
        },
      });
    });
  };
};

export const getSalutation = () => {
  return (dispatch) => {
    dispatch({ type: SALUTATION_START, isFetching: true });
    const url = getSalutationURL();
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: SALUTATION_SUCCESS,
          payload: {
            salutation: response.data,
            isFetching: false,
            //  fetchSuccess: true,
            isStep1PicklistFail: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: SALUTATION_FAILURE,
          payload: {
            error: "Could not get salutation",
            isFetching: false,
            isStep1PicklistFail: true,
          },
        });
      });
  };
};

export const relationshipWithBank = () => {
  return (dispatch) => {
    dispatch({ type: RELATION_WITH_BANK_START, isFetching: true });
    const url = relationshipWithBankURL();
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: RELATION_WITH_BANK_SUCCESS,
          payload: {
            relationshipData: response.data,
            isFetching: false,
            //  fetchSuccess: true,
            isStep1PicklistFail: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: RELATION_WITH_BANK_FAILURE,
          payload: {
            error: "Could not get bank relation data",
            isFetching: false,
            isStep1PicklistFail: true,
          },
        });
      });
  };
};

export const getOpportunityfrequency = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_OPORTUNITYFREQUENCY_START, isFetching: true });
    const url = getOpportunityfrequencyURL();
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_OPORTUNITYFREQUENCY_SUCCESS,
          payload: {
            opportunityFrequency: response.data,
            isFetching: false,
            //  fetchSuccess: true,
            isStep2PicklistFail: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ALL_OPORTUNITYFREQUENCY_FAILURE,
          payload: {
            error: "",
            ...error,
            isFetching: false,
            isStep2PicklistFail: true,
          },
        });
      });
  };
};

export const getSponsorcategory = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_SPONSORCATEGORY_START, isFetching: true });
    const url = getSponsorcategoryURL();
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_SPONSORCATEGORY_SUCCESS,
          payload: {
            sponsorCategory: response.data,
            isFetching: false,
            //  fetchSuccess: true,
            isStep2PicklistFail: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ALL_SPONSORCATEGORY_FAILURE,
          payload: {
            error: "",
            ...error,
            isFetching: false,
            isStep2PicklistFail: true,
          },
        });
      });
  };
};

export const getLowmoderateincomebenefits = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ALL_LOWMODERATEINCOMEBENEFITS_START,
      isFetching: true,
    });
    const url = getLowmoderateincomebenefitsURL();
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_LOWMODERATEINCOMEBENEFITS_SUCCESS,
          payload: {
            lowModerateIncomeBenefits: response.data,
            isFetching: false,
            //  fetchSuccess: true,
            isStep2PicklistFail: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ALL_LOWMODERATEINCOMEBENEFITS_FAILURE,
          payload: {
            error: "",
            ...error,
            isFetching: false,
            isStep2PicklistFail: true,
          },
        });
      });
  };
};

export const getOpportunitystate = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_OPPORTUNITYSTATES_START, isFetching: true });
    const url = getOpportunitystateURL();
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_OPPORTUNITYSTATES_SUCCESS,
          payload: {
            opportunityStates: response.data,
            isFetching: false,
            //  fetchSuccess: true,
            isStep2PicklistFail: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ALL_OPPORTUNITYSTATES_FAILURE,
          payload: {
            error: "",
            ...error,
            isFetching: false,
            isStep2PicklistFail: true,
          },
        });
      });
  };
};

export const organizationstates = () => {
  return (dispatch) => {
    dispatch({ type: ORGANIZATION_STATE_START, isFetching: true });
    const url = organizationstatesURL();
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: ORGANIZATION_STATE_SUCCESS,
          payload: {
            organizationData: response.data,
            isFetching: false,
            //  fetchSuccess: true,
            isStep1PicklistFail: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: ORGANIZATION_STATE_FAILURE,
          payload: {
            error: "Could not get organization data",
            isFetching: false,
            isStep1PicklistFail: true,
          },
        });
      });
  };
};

export const setActiveProposal = (proposalId) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_PROPOSAL_START,
      payload: {
        id: proposalId,
        isFetching: true,
        isProposalSubmitted: false,
        isProposalFailed: false,
      },
    });
    const url = setActiveProposalURL(proposalId);
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: SET_ACTIVE_PROPOSAL,
          payload: { id: proposalId, proposalData: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ACTIVE_PROPOSAL_FAILURE,
          payload: { error: "Could not get active data", isFetching: false },
        });
      });
  };
};

export const submitProposal = (proposalId) => {
  const requestBody = {
    ProposalId: proposalId,
  };
  return (dispatch) => {
    dispatch({
      type: SUBMIT_PROPOSAL_START,
      payload: {
        isFetching: true,
        isProposalSubmitted: false,
        isProposalFailed: false,
      },
    });
    const url = submitProposalURL();
    http
      .post(url, requestBody)
      .then((response) => {
        dispatch({
          type: SUBMIT_PROPOSAL_SUCCESS,
          payload: {
            proposalSucess: response.data,
            isFetching: false,
            isProposalSubmitted: true,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: SUBMIT_PROPOSAL_FAILURE,
          payload: { isFetching: false, isProposalFailed: true },
        });
      });
  };
};

export const getOpportunitycategories = () => {
  return (dispatch) => {
    dispatch({
      type: OPPORTUNITY_CATEGORY_START,
      payload: { isFetching: true },
    });
    const url = getOpportunitycategoriesURL();
    http
      .get(url)
      .then((response) => {
        dispatch({
          type: OPPORTUNITY_CATEGORY_SUCCESS,
          payload: {
            opportunitycategories: response.data,
            isFetching: false,
            isStep2PicklistFail: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: OPPORTUNITY_CATEGORY_FAILURE,
          payload: { error: "", isFetching: false, isStep2PicklistFail: true },
        });
      });
  };
};

export const sendOtp = (proposalId, resend) => {
  const requestBody = {
    ProposalID: proposalId,
    Resend: resend,
  };
  return (dispatch) => {
    dispatch({
      type: SEND_OTP_START,
      payload: {
        isFetching: true,
        isSendOtpSubmitted: false,
        isSendOtpFailed: false,
      },
    });
    const url = sendOTPURL();
    http
      .post(url, requestBody)
      .then((response) => {
        dispatch({
          type: SEND_OTP_SUCCESS,
          payload: {
            sendOtpSucess: response.data,
            isFetching: false,
            isSendOtpSubmitted: true,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: SEND_OTP_FAILURE,
          payload: { isFetching: false, isSendOtpFailed: true },
        });
      });
  };
};

export const clearValidateOtp = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_VALIDATE_OTP_SUCCESS,
      payload: {
        isFetching: true,
        isSendOtpSubmitted: false,
        isSendOtpFailed: false,
        sendOtpSucess: "",
      },
    });
  };
};

export const clearSendOtp = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEND_OTP_SUCCESS,
      payload: {
        isFetching: false,
        isValidateOtpSubmitted: false,
        isValidateOtpFailed: false,
        status: "",
      },
    });
  };
};

export const validateOtp = (proposalId, code) => {
  const requestBody = {
    ProposalID: proposalId,
    Code: code,
  };
  return (dispatch) => {
    dispatch({
      type: VALIDATE_OTP_START,
      payload: {
        isFetching: true,
        isValidateOtpSubmitted: false,
        isValidateOtpFailed: false,
      },
    });
    const url = validateOTPURL();
    http
      .post(url, requestBody)
      .then((response) => {
        dispatch({
          type: VALIDATE_OTP_SUCCESS,
          payload: {
            validateOtpSucess: response.data,
            isFetching: false,
            isValidateOtpSubmitted: true,
            status: response.status,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: VALIDATE_OTP_FAILURE,
          payload: {
            isFetching: false,
            isValidateOtpFailed: true,
            status: error?.response?.status,
          },
        });
      });
  };
};

// export const getMedia = (fileName, proposalId, typeId) => {
//   return dispatch => {
//     dispatch({ type: GET_MEDIA_START, isFetching: true });
//     console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"+JSON.stringify(typeId));
//     http
//       .get(
//         "https://sponsorshipresource.azurewebsites.net/api/proposal/viewmedia?proposalID="+proposalId+'&fileName='+fileName
//       )
//       .then(response => {

//         dispatch({
//           type: GET_MEDIA_SUCCESS,
//           payload: {
//             getFileData: {'fileName': response.data, TypeId: typeId },
//             isFetching: false,
//           }
//         });
//       })
//       .catch(error => {
//           dispatch({
//             type: GET_MEDIA_FAILURE,
//             payload: {
//               error: "Could not upload file.",
//               isFetching: false
//             }
//           });
//       });
//   };
// };
