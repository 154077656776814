import authentication from '../ducks/authentication';
import proposals from '../ducks/proposals';
import techHelp from '../ducks/techHelp';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
	authentication,
	proposals,
	techHelp
});

export default rootReducer;
