import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Card from "../atoms/card/Card";

class MaintenancePage extends Component {
  state = {};

  render() {
    return (
      <div>
        <section>
          <Card white noPaddingDesktop>
            <p>
            The sponsorship portal is currently under maintenance. Please try back at 7 AM Eastern Time.
            </p>
          </Card>
        </section>
      </div>
    );
  }
}

export default withRouter(MaintenancePage);
