import React, { Suspense, Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import ROUTES from "./constants/routes";
import "./App.sass";
import Header from "./components/organisms/header/Header";
import PrivateRoute from "./components/helpers/PrivateRoute";
import Footer from "./components/organisms/footer/Footer";
import ScrollToTop from "./components/helpers/ScrollToTop";
import HOC from "./components/pages/hoc";
import { NoAccess, Restricted } from "./components/pages";
class App extends Component {
  state = { isVisibleData: true };

  componentDidMount() {
    this.setState({ isVisibleData: true });
  }

  isVisiblePage = (val) => {
    this.setState({ isVisibleData: !val });
  };

  render() {
    const { isVisibleData } = this.state;
    if (window.APP.SITECONFIG.IsInMaintenance) {
      return (
        <React.Fragment>
          <Header isVisible={!window.APP.SITECONFIG.IsInMaintenance} />
          <main>
            <Container fluid>
              <Suspense fallback={<div>Loading...</div>}>
                <ScrollToTop>
                  <Switch>
                    <Redirect exact to={ROUTES.maintenance.route} from="/" />
                    <Route
                      exact
                      to="*"
                      component={ROUTES.maintenance.component}
                    />
                  </Switch>
                </ScrollToTop>
              </Suspense>
            </Container>
          </main>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Header isVisible={isVisibleData} />
          <main>
            <Container fluid>
              <Suspense fallback={<div>Loading...</div>}>
                <ScrollToTop>
                  <Switch>
                    <Redirect exact to={ROUTES.login.route} from="/" />
                    <Route
                      path={ROUTES.defaultPage.route}
                      component={ROUTES.defaultPage.component}
                    />
                    <Route
                      path={ROUTES.login.route}
                      component={ROUTES.login.component}
                    />
                    <Route
                      path={ROUTES.verify.route}
                      component={ROUTES.verify.component}
                    />
                    <Route
                      path={ROUTES.faq.route}
                      component={ROUTES.faq.component}
                    />
                    <Route
                      path={ROUTES.pwReset.route}
                      component={ROUTES.pwReset.component}
                    />
                    <Route
                      path={ROUTES.pwChange.route}
                      component={ROUTES.pwChange.component}
                    />
                    <Route
                      path={ROUTES.techHelp.route}
                      component={ROUTES.techHelp.component}
                    />
                    <Route
                      path={ROUTES.signup.route}
                      component={ROUTES.signup.component}
                    />
                    <Route
                      exact
                      path={ROUTES.emulateUser.route}
                      component={ROUTES.emulateUser.component}
                    />
                    <Route
                      path={ROUTES.noAccess.route}
                      render={(props) => (
                        <NoAccess
                          {...props}
                          isVisiblePage={this.isVisiblePage}
                        />
                      )}
                    />
                    <Route
                      path={ROUTES.restricted.route}
                      render={(props) => (
                        <Restricted
                          {...props}
                          isVisiblePage={this.isVisiblePage}
                        />
                      )}
                    />
                    <PrivateRoute
                      path={ROUTES.home.route}
                      component={HOC(ROUTES.home.component)}
                    />
                    <PrivateRoute
                      path={ROUTES.upload.route}
                      component={HOC(ROUTES.upload.component)}
                    />
                    <Route
                      exact
                      to={ROUTES.notFound.route}
                      component={ROUTES.notFound.component}
                    />
                  </Switch>
                </ScrollToTop>
              </Suspense>
            </Container>
          </main>
          {<Footer isVisible={isVisibleData} />}
        </React.Fragment>
      );
    }
  }
}

export default App;
