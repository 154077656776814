let acceptableFileExtensions = [
  'pdf',
  'docx',
  'doc',
  'jpg',
  'xlsx',
  'msg',
  'pptx',
  'tif',
  'wmv',
  'xls',
  'png',
  'txt',
  'ppt',
  'mp4',
  'csv',
  'mp3',
  'mov',
  'eps',
  'ai',
  'psd',
  'rtf',
  'tiff',
  'gif',
  'swf',
  'xlsm',
  'indd',
  'oft',
  'docm',
  'jpeg',
  'mpeg',
  'bmp',
  'pub',
  'pptm',
  'wav',
  'mpg',
  'mpp',
  'dot',
  'xps',
  'avi',
  'fla',
  'ppsx',
  'ps',
  'm4v',
  'sea',
  'idml',
  'page',
  'eml',
  'aif',
  'potx',
  'vsd',
  'indt',
  'snag',
  'ics',
  'log',
  'wma',
  'dotx',
  'inx',
  'xlsb',
  'mdi',
  'pps',
  'aiff',
  'otf',
  'asf',
  'xlt',
  '3gp',
  'dotm',
  'dpp',
  'ico',
  'pfb',
  'pfm',
  'ttf',
  'vsdx',
  'm4a',
  'wpp'
]

acceptableFileExtensions = acceptableFileExtensions.map(ext => '.' + ext)

export default acceptableFileExtensions
