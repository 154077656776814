import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import ROUTES from '../../constants/routes';

const PrivateRoute = ({ isAuthenticated, ...rest }) => {
	return isAuthenticated ? <Route {...rest} /> : <Redirect to={ROUTES.login.route} />;
};

const mapStateToProps = ({ authentication }) => {
	return { isAuthenticated: authentication.isAuthenticated };
};

export default connect(mapStateToProps)(PrivateRoute);
