import React from 'react'

const PillIcon = ({ className = '', knockout = false, ...props}) => {
  return (
    <svg
      height='40'
      viewBox='0 0 80 40'
      width='80'
      xmlns='http://www.w3.org/2000/svg'
      focusable='false'
      className={`pill-icon ${className}`}
      alt=''
      aria-hidden='true'
      {...props}
    >
      <path d='m60 0h-40c-11.2 0-20 8.8-20 20s8.8 20 20 20h40c11.2 0 20-8.8 20-20s-8.8-20-20-20zm-24.4 36h-15.6c-8.8 0-16-7.2-16-16s7.2-16 16-16h23.6zm32.8-8.8-2.8 2.8-7.6-7.6-7.6 7.6-2.8-2.8 7.6-7.6-7.6-7.6 2.8-2.8 7.6 7.6 7.6-7.6 2.8 2.8-7.6 7.6zm-47.88 2.04-9.08-8.6 2.76-2.92 6.12 5.8 14.24-15.68 2.96 2.68z' {  ...(knockout && { fill: '#fff' })}/>
    </svg>
  )
}

export default PillIcon;