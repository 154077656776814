/**
 * For consistency techHelp request is going through redux
 */

// action types
import http from '../../constants/token-interceptor';
import { getRequestTypeURL, sendTechRequestURL } from '../../constants/microservices-helper';
const TECH_HELP_REQ_START = 'sponsorship/techHelp/TECH_HELP_REQ_START';
const TECH_HELP_REQ_SUCCESS = 'sponsorship/techHelp/TECH_HELP_REQ_SUCCESS';
const TECH_HELP_REQ_FAILURE = 'sponsorship/techHelp/TECH_HELP_REQ_FAILURE';
const TECH_HELP_REQ_RESET = 'sponsorship/techHelp/TECH_HELP_REQ_RESET';
const TECH_HELP_REQ_TYPE_START = 'sponsorship/techHelp/TECH_HELP_REQ_TYPE_START';
const TECH_HELP_REQ_TYPE_SUCCESS = 'sponsorship/techHelp/TECH_HELP_REQ_TYPE_SUCCESS';
const TECH_HELP_REQ_TYPE_FAILURE = 'sponsorship/techHelp/TECH_HELP_REQ_TYPE_FAILURE';

export default function reducer(state = {}, { type, payload }) {
	switch (type) {
		case TECH_HELP_REQ_START:
		case TECH_HELP_REQ_SUCCESS:
		case TECH_HELP_REQ_FAILURE:
			return { ...state, ...payload };
		case TECH_HELP_REQ_TYPE_START:
		case TECH_HELP_REQ_TYPE_FAILURE:
			return { ...state, ...payload };
		case TECH_HELP_REQ_TYPE_SUCCESS:
			return { ...state, ...payload };
		case TECH_HELP_REQ_RESET:
			return {};
		default:
			return state;
	}
}

export const resetTechRequest = () => ({
	type: TECH_HELP_REQ_RESET,
	payload: {}
});

export const getRequestType = () => {
	return (dispatch) => {
		dispatch({ type: TECH_HELP_REQ_TYPE_START, payload: { isFetching: true } });
		const url = getRequestTypeURL();
		http
			.get(url)
			.then((response) => {
				dispatch({
					type: TECH_HELP_REQ_TYPE_SUCCESS,
					payload: {
						requestType: response.data,
						isAuthenticated: false,
						isFetching: false
					}
				});
			})
			.catch((error) => {
				dispatch({
					type: TECH_HELP_REQ_TYPE_FAILURE,
					payload: { error: '', isAuthenticated: false, isFetching: false }
				});
			});
	};
};

export const sendTechRequest = (requestData) => {
	return (dispatch) => {
		dispatch({ type: TECH_HELP_REQ_START, payload: { isFetching: true } });
		const url = sendTechRequestURL();
		http
			.post(url, requestData)
			.then((response) => {
				dispatch({
					type: TECH_HELP_REQ_SUCCESS,
					payload: {
						isFetching: false,
						fetchSuccess: true
					}
				});
			})
			.catch((error) => {
				dispatch({
					type: TECH_HELP_REQ_SUCCESS,
					payload: {
						error: 'Could not send request. Please try again.',
						isFetching: false,
						fetchSuccess: false
					}
				});
			});
	};
};
