// import React from 'react';

const API_CONST = {
  STATE: '/list/location?stateAbb=',
  RESETPASSWORD: '/user/resetpassword',
  CLEARACTIVEPROPOSALS: '/list/states',
  GETALLPROPOSALS: '/proposal/getproposals',
  VERIFY: '/proposal/verify',
  TARGETLOCATION: '/proposal/targetlocation',
  DELETEPROPSAL: '/proposal/deleteproposal?proposalId=',
  SAVESTEP: '/proposal/',
  MEDIAUPLOAD: '/proposal/mediaupload?index=',
  MEDIADELETE: '/proposal/mediadelete',
  GETSALUTATION: '/list/salutations',
  RELATIONSHIPWITHBANK: '/list/relationshipwithbank',
  OPPRTUNITYFREQUENCY: '/list/opportunityfrequency',
  SPONSORCATEGORY: '/list/sponsorcategories',
  LOWMODERATEINCOMEBENEFITS: '/list/lowModerateincomebenefits',
  OPPORTUNITYSTATE: '/list/opportunitystate',
  ORGANIZATIONSTATES: '/list/organizationstates',
  SETACTIVEPROPOSALS: '/proposal/getproposal?proposalId=',
  SUBMITPROPOSAL: '/proposal/submit',
  OPPORTUNITYCATEGORIES: '/list/opportunitycategories',
  SENDOTP: '/proposal/sendotp',
  VALIDATEOTP: '/proposal/validateotp',
  LOGOUT: '/user/logout',
  LOGIN: 'oauth/token',
  SIGNUP:"/user/createaccount",
  FORGOTPASSWORD:'/user/forgotpassword',
  CHANGEPASSWORD:'/user/changepassword',
  USERPROFILE:'/user/me',
  EMULATEUSERLOGIN:'oauth/token',
  EMULATEUSERAUTHORIZATION: 'oauth/token',
  REQUESTTYPE: '/list/requesttypes',
  SENDTECHREQUEST:'/support/createsupportrequest'
};

export default API_CONST;
