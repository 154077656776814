import React from 'react';
import './Button.sass';
import { ArrowLeft, ArrowRight } from '../../../assets/Arrows';

// eslint-disable-next-line react/display-name
const Button = React.forwardRef(function buttonAtom(
	{
		blue,
		red,
		redOutline,
		link,
		transparent,
		block,
		arrowRight,
		arrowLeft,
		children,
		className,
		disabled,
		blueOutline,
		...props
	},
	ref
) {
	let typeClass = '';
	typeClass = blue ? `btn-primary blue` : typeClass;
	typeClass = redOutline ? 'btn-primary red-outline' : typeClass;
	typeClass = red ? 'btn-primary red' : typeClass;
	typeClass = transparent ? 'btn-primary transparent-btn' : typeClass;
	typeClass = link ? 'btn-primary button-link' : typeClass;
	const isBlock = block ? 'block' : '';
	const isDisabled = disabled ? `disabled` : ``;
	return (
		<button className={`btn ${className || ''} ${typeClass} ${isBlock}`} ref={ref} disabled={isDisabled} {...props}>
			{arrowLeft && <ArrowLeft />}
			{children}
			{arrowRight && <ArrowRight />}
		</button>
	);
});

export default Button;
