export const ERRORS_CONST = {
  ERROR_PASSWORD_RESET: {
    type: 'ERROR_PASSWORD_RESET',
    alert: `There was a problem with the request to reset your password. Please try again.`
  },
  ERROR_FETCHING_PROPOSALS: {
    type: 'ERROR_FETCHING_PROPOSALS',
    alert: `Could not retrieve proposals.`
  },
  ERROR_VERIFY_PROPOSALS: {
    type: 'ERROR_VERIFY_PROPOSALS',
    alert: `Could not verify proposals.`
  },
  ERROR_TARGETLOCATION_PROPOSALS: {
    type: 'ERROR_TARGETLOCATION_PROPOSALS',
    alert: `Could not find target location.`
  }
}

export default ERRORS_CONST
