import {
  Styleguide,
  Step0,
  Step1,
  Step2,
  Step3,
  Step4,
  Dashboard,
  Login,
  SignUp,
  Faq,
  TechHelp,
  ChangePassword,
  ResetPassword,
  Home,
  NotFound,
  EmulateUser,
  NoAccess,
  Restricted,
  DefaultPage,
  Verify,
  Upload
} from "../components/pages";
import Maintenance from "../components/pages/Maintenance";

const ROUTES = {
  login: {
    route: "/login",
    component: Login,
    protected: false,
    root: true
  },
  verify: {
    route: "/verify",
    component: Verify,
    protected: false,
    root: true
  },
  upload: {
    route: "/uploads",
    component: Upload,
    protected: true,
  },
  signup: {
    route: "/signup",
    component: SignUp,
    protected: false
  },
  dashboard: {
    route: "/home/dashboard",
    component: Dashboard,
    protected: true
  },
  eligibility: {
    route: "/home/eligibility",
    component: Step0,
    protected: true
  },
  step1: {
    route: "/home/step1/:id",
    component: Step1,
    protected: true
  },
  step2: {
    route: "/home/step2/:id",
    component: Step2,
    protected: true
  },
  step3: {
    route: "/home/step3/:id",
    component: Step3,
    protected: true
  },
  step4: {
    route: "/home/step4/:id",
    component: Step4,
    protected: true
  },
  faq: {
    route: "/faq",
    component: Faq,
    protected: false
  },
  faqAuth: {
    route: "/home/faq",
    component: Faq,
    protected: true
  },
  techHelp: {
    route: "/help",
    component: TechHelp,
    protected: false
  },
  techHelpAuth: {
    route: "/home/help",
    component: TechHelp,
    protected: true
  },
  styleGuide: {
    route: "/style",
    component: Styleguide,
    protected: false
  },
  pwChange: {
    route: "/changepassword",
    component: ChangePassword,
    protected: false
  },
  pwReset: {
    route: "/resetpassword",
    component: ResetPassword,
    protected: false
  },
  home: {
    route: "/home",
    component: Home,
    protected: true
  },
  notFound: {
    route: "*",
    component: NotFound,
    protected: true
  },
  emulateUser: {
    route: "/emulateUser",
    component: EmulateUser,
    protected: false
  },
  noAccess: {
    route: "/noAccess",
    component: NoAccess,
    protected: false
  },
  restricted: {
    route: "/restricted",
    component: Restricted,
    protected: false
  },
  defaultPage: {
    route: "/default",
    component: DefaultPage,
    protected: false
  },
  maintenance: {
    route: "/maintenance",
    component: Maintenance,
    protected: false
  },
};

export default ROUTES;
