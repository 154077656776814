import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Modal as BsModal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  List,
} from "reactstrap";
import Button from "../../../atoms/button/Button";
import CloseIcon from "../../../../assets/CloseIcon";
import PauseIcon from "../../../../assets/images/icon_uncategroized-bofa.svg";
import HelpIcon from "../../../../assets/images/icon_help-bofa.svg";
import AlertIcon from "../../../../assets/images/icon_alert-bofa.svg";
import { NavLink } from "react-router-dom";

import DocumentIcon from "../../../../assets/images/icon_document2-bofa.svg";
import ErrorIcon from "../../../../assets/images/icon_alert-bofa.svg";
import SuccessIcon from "../../../../assets/images/icon_success_green-bofa.svg";
import LockIcon from "../../../../assets/images/boa-lock-icon.svg";
import CompanyNames from "../../../../constants/companyNames";

// import LikeIcon from "../../../../assets/images/icon_like-bofa.svg";
import "./Modal.sass";
import arrayChunk from "array-chunk";
import acceptableFileExtensions from "../../../../constants/acceptableFileExtensionsUpload";
import DocumentsIcon from "../../../../assets/images/icon_documents.svg";
import LikeIcon from "../../../../assets/images/bofa_icon_like.svg";

const acceptableFileExtensionsChunks = arrayChunk(
  acceptableFileExtensions,
  Math.ceil(acceptableFileExtensions.length / 6)
);

export default function Modal({
  show,
  showCloseButton,
  backdrop,
  trigger,
  triggerEl,
  triggerProps,
  footerButtonProps,
  children,
  className,
  render,
  sayTitle,
  openOnClick,
  loading,
  ...props
}) {
  const { children: triggerChildren, ...restTriggerProps } = triggerProps;
  const [isOpen, setIsOpen] = useState(false);
  const [closeButtonRef, setCloseButtonRef] = useState(null);
  const updateCloseButtonRef = useCallback((node) => {
    setCloseButtonRef(node);
  });
  const Trigger = triggerEl;
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  // const onClickProxy = (e) => {
  //   const { onClick } = restTriggerProps;
  //   typeof onClick === "function" && onClick(e);
  //   openOnClick && toggle();

  // };

  const onClickProxy = async (e) => {
    e.preventDefault();
    const { onClick } = restTriggerProps;
    if (typeof onClick === "function") {
      await onClick(e); // Wait for onClick(e) to finish
    }
    openOnClick && toggle();
  };

  useEffect(() => {
    if (show !== null && show !== undefined) {
      setIsOpen(show);
    }
  }, [show]);

  // useEffect(() => {
  //   if (isOpen) {
  //     // dialogRef.current is initially null and does not change immediately when the modal opens.
  //     // The setTimeout ensures that the conditional statement is executed.
  //     const timeoutId = setTimeout(() => {
  //       if (dialogRef.current) {
  //         const modalHeading = document.getElementById("modal-header");
  //         dialogRef.current.setAttribute("aria-labelledby", modalHeading.id);
  //       }
  //     }, 0);

  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [isOpen]);

  useEffect(() => {
    if (isOpen && closeButtonRef) {
      closeButtonRef.focus();
    }
  }, [isOpen, closeButtonRef]);

  const closeButtonLabel = `You are now in ${
    sayTitle ? `the ${sayTitle}` : `a`
  } modal window. Press enter to close modal.`;

  return (
    <>
      {trigger && (
        <Trigger {...restTriggerProps} onClick={onClickProxy}>
          {triggerChildren}
        </Trigger>
      )}
      <BsModal
        keyboard={false}
        backdrop={backdrop}
        centered
        isOpen={isOpen}
        className={`base-modal ${className || ""}`}
        toggle={toggle}
        {...props}
      >
        <div
          className="base-modal-header"
          style={{ height: !showCloseButton && 18 }}
        >
          {showCloseButton && (
            <Button
              transparent
              onClick={toggle}
              ref={updateCloseButtonRef}
              aria-label={closeButtonLabel}
            >
              <CloseIcon />
            </Button>
          )}
        </div>

        <ModalBody>{(render && render({ toggle })) || children}</ModalBody>
        {footerButtonProps && footerButtonProps.length && (
          <ModalFooter>
            {footerButtonProps.map((el, i) => {
              const { children: footerChildren, ...footerProps } =
                footerButtonProps[i];
              return (
                <Button
                  key={i}
                  block
                  {...footerProps}
                  onClick={(e) => {
                    const { onClick: footerOnClick } = footerProps;
                    typeof footerOnClick === "function" && footerOnClick(e);
                    toggle();
                  }}
                >
                  {footerChildren}
                </Button>
              );
            })}
          </ModalFooter>
        )}
      </BsModal>
    </>
  );
}

Modal.defaultProps = {
  openOnClick: true,
  show: null,
  showCloseButton: true,
  backdrop: true,
  trigger: true,
  triggerEl: Button,
  triggerProps: {},
};
/** all other props aside from listed below (and render) are
 * directed to Modal props ( uses reactstrap mdoal comonent)
 */
Modal.propTypes = {
  openOnClick: PropTypes.bool,

  // if preset will show or hide modal
  show: PropTypes.bool,
  // set to false to hide close button
  showCloseButton: PropTypes.bool,
  // options for backdrop
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["static"])]),
  // tells the modal to include a trigger element
  trigger: PropTypes.bool,
  // the trigger to pop the modal
  triggerEl: PropTypes.any,
  // props to pass down to trigger
  triggerProps: PropTypes.object,
  // length of array is how many buttons populate modal
  footerButtonProps: PropTypes.arrayOf(PropTypes.object),
};

export const ModalHeader = ({ icon, children, ...props }) => {
  const [headingRef, setHeadingRef] = useState(null);
  const updateHeadingRef = useCallback((node) => setHeadingRef(node));

  useEffect(() => {
    if (headingRef) {
      headingRef.focus();
    }
  }, [headingRef]);
  return (
    // <div className="base-modal-title-container mt-n3 mb-3">
    //   {icon && <img src={icon} alt="" />}
    //   <h1 className={`mb-0 ${icon ? "ml-3" : ""}`}>{children}</h1>
    // </div>
    <div className="base-modal-title-container mt-n3 mb-3">
      {icon && <img src={icon} alt="" />}
      <h1
        id="modal-header"
        className={`mb-0 ${icon ? "px-3" : ""}`}
        tabIndex="0"
        ref={updateHeadingRef}
      >
        {children}
      </h1>
    </div>
  );
};

ModalHeader.propTypes = {
  // path to image
  icon: PropTypes.string,
};

export const MODAL_CONTENT = {
  acceptedFormats: (
    <>
      <ModalHeader icon={DocumentsIcon}>Accepted file formats</ModalHeader>
      <List className="columns">
        {acceptableFileExtensions.map((ext, key) => (
          <li key={key}>{ext}</li>
        ))}
      </List>
    </>
  ),
  invoiceRequirements: (
    <>
      <ModalHeader>Invoice Requirements</ModalHeader>
      <List>
        <li>Invoice number</li>
        <li>Invoice date</li>
        <li>Invoice amount</li>
        <li>Vendor name (Matching your W9)</li>
        <li>&ldquo;Remit to&rdquo; address</li>
        <li>
          &ldquo;Bill to&rdquo; address:
          <List className="dashed-list">
            <li>{CompanyNames.boa}</li>
            <li>Attn: (employee name)</li>
            <li>BANK ADDRESS, BANK CITY, STATE, ZIP CODE</li>
          </List>
        </li>
      </List>
    </>
  ),
  w9Requirements: (
    <>
      <ModalHeader>W-9 Requirements</ModalHeader>
      <List>
        <li>This form needs to be fully completed, signed and dated</li>
        <li>
          Vendor (payee) name matches exactly what is on the invoice and ACH
        </li>
        <li>Include a physical address</li>
        <li>
          Check the appropriate box designating your appropriate organization
          type (only one box can be checked and do not write in section 3,
          unless the &ldquo;Other&rdquo; box is checked)
        </li>
      </List>
    </>
  ),
  achFormRequirements: (
    <>
      <ModalHeader>ACH Form Requirements</ModalHeader>
      <List>
        <li>
          Include the routing number, account number and specify whether it is a
          checking or savings
        </li>
        <li>
          Supplier Name must match exactly what is on your W-9 and Invoice
        </li>
        <li>
          Include a valid email address (email address will be used to notify
          when the payment is deposited)
        </li>
        <li>Do not cross out any information and write over</li>
      </List>
    </>
  ),
  caForm590Requirements: (
    <>
      <ModalHeader>CA Form 590 Requirements</ModalHeader>
      <List>
        <li>
          Be sure that the Withholding Agent Information is not left blank (if
          completing this for Bank&nbsp;of&nbsp;America,
          Bank&nbsp;of&nbsp;America would be considered the withholding agent)
        </li>
        <li>
          For Payee Information, use dashes while completing the number section
        </li>
        <li>
          Check the appropriate box designating your appropriate organization
          type (only one box can be checked), ensure this information matches
          the W-9
        </li>
        <li>Ensure all lines are completed for the Certificate of Payee</li>
      </List>
    </>
  ),
  form501C4Requirements: (
    <>
      <ModalHeader>
        Political Activities Letter 501(c)(4) Requirements
      </ModalHeader>
      <List>
        <li>
          This letter is required because of your organization’s status as a
          501(c)(4)
        </li>
        <li>
          The letter needs to be uploaded on organization letterhead. Reach out
          to your BofA contact with any questions regarding the content
        </li>
      </List>
    </>
  ),
  form501C6Requirements: (
    <>
      <ModalHeader>
        Political Activities Letter 501(c)(6) Requirements
      </ModalHeader>
      <List>
        <li>
          This letter is required because of your organization’s status as a
          501(c)(6)
        </li>
        <li>
          The letter needs to be uploaded on organization letterhead. Reach out
          to your BofA contact with any questions regarding the content
        </li>
      </List>
    </>
  ),
  logoLicenseAgreementRequirements: (
    <>
      <ModalHeader>Logo License Agreement Requirements</ModalHeader>
      <List>
        <li>Use todays date as start date for agreement</li>
        <li>
          Territory refers to the state(s) in which any materials would be
          viewed
        </li>
        <li>License Activities will be the name of the event/sponsorship</li>
      </List>
    </>
  ),
  submissionSuccessful: (
    <>
      <ModalHeader icon={LikeIcon}>
        Thank you for uploading your required document(s)
      </ModalHeader>
      <p>Your document(s) will be reviewed and we will get back to you soon.</p>
    </>
  ),
  submitting: (
    <>
      <ModalHeader icon={PauseIcon}>Your form is being submitted</ModalHeader>
      Please wait while your proposal is being submitted.
    </>
  ),
  errorExistsSignUp: (
    <>
      <ModalHeader icon={AlertIcon}>An error occured</ModalHeader>
      If you continue to experience issues creating an account, please use the{" "}
      <NavLink to={`/help`} id={`sponsorship-help`}>
        Sponsorship Technical Help System
      </NavLink>{" "}
      to submit a technical support request.
    </>
  ),

  forgotPassword: (
    <>
      <ModalHeader icon={HelpIcon}>Forgot password</ModalHeader>
      <p>
        To receive your password via email, please enter your email address and
        click &ldquo;Submit&rdquo;.
        <br />
        An email with the requested information will arrive shortly.
      </p>
    </>
  ),
  forgotPasswordConfirm: (
    <>
      <ModalHeader icon={LikeIcon}>Thank you</ModalHeader>
      If your email address exists in our database, you will receive a password
      recovery link in a few minutes. If you do not receive the email, please
      contact{" "}
      <a href={"/#/help"} id={`forgot-password-help`}>
        Tech Support
      </a>
      .
    </>
  ),
  saveFailed: (
    <>
      <ModalHeader icon={ErrorIcon}>Save failed</ModalHeader>
      We were unable to save your form. Please try again.
    </>
  ),
  cannotSave: (
    <>
      <ModalHeader icon={ErrorIcon}>Form can’t be saved</ModalHeader>
      In order to save, you must first fill out all the required fields.
    </>
  ),
  saveUnavailable: (
    <>
      <ModalHeader icon={ErrorIcon}>Save unavailable</ModalHeader>
      Please answer all the required fields before moving to the next step.
    </>
  ),
  editsSaved: (
    <>
      <ModalHeader icon={SuccessIcon}>Edits saved</ModalHeader>
      Your information has been saved.
    </>
  ),
  accountLock: (
    <>
      <ModalHeader icon={LockIcon}>Your account has been locked</ModalHeader>
      For your security, and due to too many failed login attempts, your account
      has been locked. We have sent you an email to the address on record to
      reset your password.
    </>
  ),
  eligibilityTC: (
    <>
      <p>
        By clicking &ldquo;I agree&rdquo; below, you are certifying that your
        organization agrees to the terms stated below regarding the prohibition
        against using sponsorship funds for political purposes.
      </p>
      <p>
        Bank of America is regulated under federal rules that prohibit national
        banks and corporations from making a contribution in connection with any
        election to any political office, or other political activities. This
        prohibition precludes Bank of America from funding:
      </p>
      <ul>
        <li>
          Any organization that is registered or acting as a political committee
          under federal election law or any state or local election law.
        </li>
        <li>
          Any effort to support or oppose any candidates for U.S. federal, state
          or local office.
        </li>
        <li>
          Any bond initiatives or ballot initiatives, either directly or through
          transferring to any political action committee.
        </li>
      </ul>
      <p>
        If we approve your sponsorship proposal, you must only use the
        sponsorship funds for their intended purpose, and in a manner that is
        consistent with these restrictions. Specifically, these funds may not be
        used for any direct or indirect political activities. Please understand
        that by depositing or receiving these funds, or any additional funds
        that you may receive from Bank of America this year, you are agreeing to
        these requirements.
      </p>
    </>
  ),
  notEligible: (
    <div className={`pt-3`}>
      <ModalHeader icon={ErrorIcon}>Eligibility criteria not met</ModalHeader>
      Unfortunately, your responses do not meet our criteria for the following
      reasons:
    </div>
  ),
  newProposalDisabledHasDraft: (
    <>
      <ModalHeader icon={ErrorIcon}>Cannot create new proposal</ModalHeader>
      You cannot create a second draft proposal.
    </>
  ),
  newProposalDisabledNoRoom: (
    <>
      <ModalHeader icon={ErrorIcon}>Cannot create new proposal</ModalHeader>
      You cannot create a proposal because you have too many submissions in
      progress.
    </>
  ),
  acceptableFormats: (
    <>
      <ModalHeader icon={DocumentIcon}>Accepted file formats</ModalHeader>
      <Row>
        {acceptableFileExtensionsChunks.map((chunk, ix) => (
          <Col key={ix} xs={2}>
            <ul>
              {chunk.map((ext, ix) => (
                <li key={ix}>{ext.ext}</li>
              ))}
            </ul>
          </Col>
        ))}
      </Row>
    </>
  ),
  acceptableImageFormats: (
    <>
      <ModalHeader icon={DocumentIcon}>Accepted file formats</ModalHeader>
      <Row>
        {acceptableFileExtensionsChunks.map((chunk, ix) => (
          <Col key={ix} xs={2}>
            <ul>
              {chunk.map(
                (ext, ix) =>
                  ext.uploadImage === true && <li key={ix}>{ext.ext}</li>
              )}
            </ul>
          </Col>
        ))}
      </Row>
    </>
  ),
  notAcceptableFormat: (
    <>
      <ModalHeader icon={ErrorIcon}>Invalid file</ModalHeader>
      This file type is not supported.
    </>
  ),
  notAcceptableFileSize: (
    <>
      <ModalHeader icon={ErrorIcon}>Invalid file size</ModalHeader>
      This file size is greater than accepted size.
    </>
  ),
  techHelpSuccess: (
    <>
      <ModalHeader icon={LikeIcon}>
        Thank you for submitting your technical request
      </ModalHeader>
      Your ticket will be reviewed and we will get back to you soon.
    </>
  ),
  techHelpFail: (
    <>
      <ModalHeader icon={ErrorIcon}>Error</ModalHeader>
      We were unable to send your request. Please try again.
    </>
  ),
  processFail: (
    <>
      <ModalHeader icon={ErrorIcon}>Process fail</ModalHeader>
      Your request cannot be processed. Please reach out to
      <a href={"/#/help"} id={`Process-fail`}>
        Tech Support
      </a>
    </>
  ),
};
