import axios from 'axios';
const anonymousUrl = [
	{ url: 'oauth/token' },
	{ url: 'user/createaccount' },
	{ url: 'user/forgotpassword' },
	{ url: 'list/requesttypes' },
	{ url: 'support/createsupportrequest' },
	{ url: 'proposal/verify' }
];

axios.interceptors.request.use(
	function(config) {
		const accessToken = sessionStorage.getItem('access_token');
		if (!isUnAuthenticatedUrl(config)) {
			config.headers = {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + accessToken
			};
		} else if (accessToken && config.url.includes('support/createsupportrequest')) {
			config.headers = {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + accessToken
			};
		} else if (accessToken && config.url.includes('mediaupload')) {
			config.headers = {
				'Content-Type': 'multipart/form-data',
				Authorization: 'Bearer ' + accessToken
			};
		} else {
			config.headers = {
				'Content-Type': 'application/json'
			};
		}

		return config;
	},
	function(error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(null, (error) => {
	return Promise.reject(error);
});

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error && error.response) {
			const { response: { status } } = error;
			//	const originalRequest = config;
			if (status === 401) {
				// logout usr
				sessionStorage.clear();
				window.location = '/';
			} else {
				return Promise.reject(error);
			}
		}
	}
);

function isUnAuthenticatedUrl(data) {
	let isAuthenticated = false;
	anonymousUrl.find((o) => {
		return (isAuthenticated = data.url.indexOf(o.url) >= 1 ? true : false);
	});
	return isAuthenticated;
}
export default {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	delete: axios.delete
};
